@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');


html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Sulphur Point" ,'mplus-1p-regular';
}

h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

:root {
    font-size: 20px;
}

::selection {
    background: #2ddab8;
    color: white;
}

body {
    margin: 0;
    padding: 0;
    background: #0c0f13;
    overflow-x: hidden;
    font-family: 'Sulphur Point', sans-serif;
    color: white;
    font-size: 0.9rem;
}

.frame {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 2.5rem;
    z-index: 2;
}

.frame__title {
    font-size: inherit;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

.content-title {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

.content-title-ja {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

.content-subtitle {
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

a {
    color: white;
    text-decoration: none;
}

.top {
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1;
    display: flex;
    font-size: 1.2rem;
}

.top-title {
    font-size: 3rem
}

.top > div {
    flex: 1;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullpage {
    width: 100vw;
    height: 60vh;
    top: 0;
    left: 0;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .fullpage {
        height: auto;
        margin-top: 10vh;
    }
}

.bg-wedo {
    /*background-color: rgba(255, 196, 196, 0.3);*/
}

.wedo {
    width: 80vw;
    height: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
}

@media screen and (max-width: 768px) {
    .wedo {
        width: 100vw;
        flex-direction: column;
        margin: 0;
        height: auto;
    }
}

.wedo > div {
    width: 40vw;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .wedo > div {
        width: 90vw;
        margin: 5vw;
    }
}

.wedo-right > img{
    width: 100%;
    border-radius: 10px;
}

.bg-wecan {
    /*background-color: rgba(196, 255, 204, 0.3);*/
}

.wecan {
    width: 80vw;
    height: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 768px) {
    .wecan {
        flex-direction: column-reverse;
        width: 100vw;
        margin: 0;
        height: auto;
    }
}

.wecan > div {
    width: 40vw;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media screen and (max-width: 768px) {
    .wecan > div {
        width: 90vw;
        margin: 5vw;
    }
}

.wecan > div > img {
    width: 100%;
    border-radius: 10px;
}

.works {
    width: 80vw;
    height: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .works {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.works-card {
    display: flex;
    width: 80vw;
    flex-direction: row;
    gap: 1rem;
}

.bg-about {
    /*background-color: rgba(196, 196, 255, 0.3);*/
}

.about {
    width: 80vw;
    height: 100%;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
    .about {
        flex-direction: column;
        width: 100vw;
        margin: 0;
        height: auto;
    }
}

.about > div {
    width: 40vw;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .about > div {
        width: 90vw;
        margin: 5vw;
    }
    .contact {
        text-align: center;
    }
}

.bg-contact {
    background-color: rgba(86, 86, 86, 0.3);
}

/*.contact {*/
/*    text-align: center;*/
/*    height: 60vh;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.contact > div {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    text-align: center;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.about > div > div {
    background-color: #0c0f13;
    padding: 40px;
    border-radius: 10px;
}

.pb-2 {
    padding-bottom: 2rem;
}

.footer {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.member {
    width: 80vw;
    height: auto;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.member-cards {
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .member-cards {
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.member-card {
    display: flex;
    max-width: 35vw;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: 1vw;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .member-card {
        max-width: 90vw;
        margin: 5vw;
    }
}


.member-card > img {
    width: 90%;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .member-card > img {
        width: 100%;
    }
}

.member-title {
    margin: 30px
}

.member-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px
}

.member-role {
    font-size: 0.8rem;
    font-weight: normal;
    margin: 0;
}
